import { Component, OnInit } from '@angular/core';
import { Fraccion1Service } from 'src/services/Articulo8/Fraccion1.service';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-in6-d',
  templateUrl: './in6-d.component.html',
  styleUrls: ['./in6-d.component.scss']
})
export class In6DComponent implements OnInit {
  docs: any;
  docs18: any;
  docs19: any;
  docs20: any;
  docs21: any;
  docs22: any;
  docs23: any;
  docs24: any;
  docs25: any;
  docsSubsidio: any;
  boton: any;
  area: any;
  carpeta:any

  borrar: string[] = [];
  valor: any;
  todo18: any;
  todo19: any;
  todo20: any;
  todo21: any;
  todo22: any;
  todo23: any;
  todo24: any;
  todo25: any;
  displayStyleEditarNombre = "none";

  eliminarForm: any = {
    ESTATUS: 0,
    NOMBRE: undefined,
    ENLACE: undefined,

  };

  constructor(private service: Fraccion1Service, private general: GeneralService, private alerts: SweetAlertService,) { }

  ngOnInit(): void {

    localStorage.setItem('fraccion', '6');
    localStorage.setItem('inciso', '4');

    if (localStorage.getItem('token')) {
      this.boton = 1;
    }


  }
  checkCheckBoxvalue18(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo18 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs18[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo18() {
    this.todo18 = true;
    let valores = this.docs18.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs18[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion18() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo18 = false;
    }
  }

  checkCheckBoxvalue19(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo19 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs19[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo19() {
    this.todo19 = true;
    let valores = this.docs19.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs19[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion19() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo19 = false;
    }
  }

  checkCheckBoxvalue20(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo20 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs20[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo20() {
    this.todo20 = true;
    let valores = this.docs20.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs20[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion20() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo20 = false;
    }
  }
  checkCheckBoxvalue21(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo21 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs21[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo21() {
    this.todo21 = true;
    let valores = this.docs21.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs21[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion21() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo21 = false;
    }
  }
  checkCheckBoxvalue22(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo22 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs22[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo22() {
    this.todo22 = true;
    let valores = this.docs22.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs22[i].ID;
      console.log(this.borrar);

    }

  }
  quitarSeleccion22() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo22 = false;
    }
  }
  checkCheckBoxvalue23(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo23 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs23[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo23() {
    this.todo23 = true;
    let valores = this.docs23.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs23[i].ID;
      console.log(this.borrar);

    }

  }
  quitarSeleccion23() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo23 = false;
    }
  }


  checkCheckBoxvalue24(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo24 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs24[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo24() {
    this.todo24 = true;
    let valores = this.docs24.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs24[i].ID;
      console.log(this.borrar);

    }

  }
  quitarSeleccion24() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo24 = false;
    }
  }

  checkCheckBoxvalue25(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo25 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs25[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo25() {
    this.todo25 = true;
    let valores = this.docs25.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs25[i].ID;
      console.log(this.borrar);

    }

  }
  quitarSeleccion25() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo25 = false;
    }
  }
  eliminarSeleccionados() {

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
      .then((res: any) => {

        if (res.isConfirmed) {
          for (let numero of this.borrar) {
            console.log(numero);
            this.eliminarForm.ID = numero;
            this.eliminarForm.ESTATUS = 1;
            this.general.eliminarEnlace(this.eliminarForm).subscribe(
              (data: any) => {

              })
            this.alerts.alertaRealizado().then((res: any) => {
              location.reload()
            })

          }

        }

      })
  }

  carp1() {
    this.carpeta = 945;
    console.log(this.carpeta);
  }

  carp2() {
    this.carpeta = 946;
    console.log(this.carpeta);
  }

  carp3() {
    this.carpeta = 947;
    console.log(this.carpeta);
  }

  carp4() {
    this.carpeta = 948;
    console.log(this.carpeta);
  }


  carp5() {
    this.carpeta = 949;
    console.log(this.carpeta);
  }

  carp6() {
    this.carpeta = 950;
    console.log(this.carpeta);
  }

















  an82018() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, this.carpeta).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
    })
  }

  an82019() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, this.carpeta).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
    })
  }
  an82020() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, this.carpeta).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
    })
  }
  an82021() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, this.carpeta).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
    })
  }
  an82022() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, this.carpeta).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
    })
  }
  an82023() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, this.carpeta).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
    })
  }

  an82024() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, this.carpeta).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs18);
    })
  }

  an82025() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2025, this.carpeta).subscribe(res => {
      this.docs25 = res.body
      console.log(this.docs18);
    })
  }
  eliminar(ID: any) {

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
      .then((res: any) => {

        if (res.isConfirmed) {
          this.eliminarForm.ID = ID;
          this.eliminarForm.ESTATUS = 1;
          this.general.eliminarEnlace(this.eliminarForm).subscribe(
            (data: any) => {


              console.log(ID);

            })
          this.alerts.alertaRealizado().then((res: any) => {
            location.reload()
          })
        }

      })


  }




  openModalEditarNombre() {
    this.displayStyleEditarNombre = "block";
  }

  closePopUpNombre() {
    this.displayStyleEditarNombre = "none";
  }


}
