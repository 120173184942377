import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Fraccion1Service } from 'src/services/Articulo8/Fraccion1.service';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-fra9',
  templateUrl: './fra9.component.html',
  styleUrls: ['./fra9.component.scss']
})
export class Fra9Component implements OnInit {
  docs: any;
      docs18: any;
      docs19: any;
      docs20: any;
      docs21: any;
      docs22: any;
      docs23: any;
      docs24: any;
      docs25: any;
      todo25:any
      boton: any;
      tri: any;
      anu: any;
      fecha: any;
      borrar: string[] = [];
      valor: any;
      todo18: any;
      todo19: any;
      todo20: any;
      todo21: any;
      todo22: any;
      todo23: any;
      todo24: any;
      displayStyleEditarNombre = "none";

      eliminarForm: any = {
        ESTATUS: 0,
        NOMBRE: undefined,
        ENLACE: undefined,

      };

      constructor(private service: GeneralService, private api: Fraccion1Service, private alerts: SweetAlertService) { }

      ngOnInit(): void {

        localStorage.setItem('fraccion', '9');
        localStorage.setItem('inciso', '0');


        if (localStorage.getItem('token')) {
          this.boton = 1;
        }

      }
      checkCheckBoxvalue18(event:any, index:any, id: any){
        console.log(event.target.checked)
        this.todo18 = null;
        this.valor = event.target.checked;
        if(this.valor === true){
          console.log('verdadero');
          console.log(index);
          this.borrar[index]=this.docs18[index].ID;
          console.log(this.borrar);

        }else if(this.valor === false){
          console.log('falso');
          console.log(index);
          let indice = this.borrar.indexOf(id);
          this.borrar.splice(indice, 1);
          console.log(this.borrar);

        }
      }

      seleccionarTodo18(){
        this.todo18 = true;
        let valores = this.docs18.length;
        for( let i = 0; i < valores; i++){
          console.log(i);
          this.borrar[i]=this.docs18[i].ID;
          console.log(this.borrar);

        }

      }

      quitarSeleccion18(){
        let valores = this.borrar.length;
        for( let i = 0; i < valores; i++){
          console.log(i);
          this.borrar.pop();
          console.log(this.borrar);
          this.todo18 = false;
        }
      }

      checkCheckBoxvalue19(event:any, index:any, id: any){
        console.log(event.target.checked)
        this.todo19 = null;
        this.valor = event.target.checked;
        if(this.valor === true){
          console.log('verdadero');
          console.log(index);
          this.borrar[index]=this.docs19[index].ID;
          console.log(this.borrar);

        }else if(this.valor === false){
          console.log('falso');
          console.log(index);
          let indice = this.borrar.indexOf(id);
          this.borrar.splice(indice, 1);
          console.log(this.borrar);

        }
      }

      seleccionarTodo19(){
        this.todo19 = true;
        let valores = this.docs19.length;
        for( let i = 0; i < valores; i++){
          console.log(i);
          this.borrar[i]=this.docs19[i].ID;
          console.log(this.borrar);

        }

      }

      quitarSeleccion19(){
        let valores = this.borrar.length;
        for( let i = 0; i < valores; i++){
          console.log(i);
          this.borrar.pop();
          console.log(this.borrar);
          this.todo19 = false;
        }
      }

      checkCheckBoxvalue20(event:any, index:any, id: any){
        console.log(event.target.checked)
        this.todo20 = null;
        this.valor = event.target.checked;
        if(this.valor === true){
          console.log('verdadero');
          console.log(index);
          this.borrar[index]=this.docs20[index].ID;
          console.log(this.borrar);

        }else if(this.valor === false){
          console.log('falso');
          console.log(index);
          let indice = this.borrar.indexOf(id);
          this.borrar.splice(indice, 1);
          console.log(this.borrar);

        }
      }

      seleccionarTodo20(){
        this.todo20 = true;
        let valores = this.docs20.length;
        for( let i = 0; i < valores; i++){
          console.log(i);
          this.borrar[i]=this.docs20[i].ID;
          console.log(this.borrar);

        }

      }

      quitarSeleccion20(){
        let valores = this.borrar.length;
        for( let i = 0; i < valores; i++){
          console.log(i);
          this.borrar.pop();
          console.log(this.borrar);
          this.todo20 = false;
        }
      }
      checkCheckBoxvalue21(event:any, index:any, id: any){
        console.log(event.target.checked)
        this.todo21 = null;
        this.valor = event.target.checked;
        if(this.valor === true){
          console.log('verdadero');
          console.log(index);
          this.borrar[index]=this.docs21[index].ID;
          console.log(this.borrar);

        }else if(this.valor === false){
          console.log('falso');
          console.log(index);
          let indice = this.borrar.indexOf(id);
          this.borrar.splice(indice, 1);
          console.log(this.borrar);

        }
      }

      seleccionarTodo21(){
        this.todo21 = true;
        let valores = this.docs21.length;
        for( let i = 0; i < valores; i++){
          console.log(i);
          this.borrar[i]=this.docs21[i].ID;
          console.log(this.borrar);

        }

      }

      quitarSeleccion21(){
        let valores = this.borrar.length;
        for( let i = 0; i < valores; i++){
          console.log(i);
          this.borrar.pop();
          console.log(this.borrar);
          this.todo21 = false;
        }
      }
      checkCheckBoxvalue22(event:any, index:any, id: any){
        console.log(event.target.checked)
        this.todo22 = null;
        this.valor = event.target.checked;
        if(this.valor === true){
          console.log('verdadero');
          console.log(index);
          this.borrar[index]=this.docs22[index].ID;
          console.log(this.borrar);

        }else if(this.valor === false){
          console.log('falso');
          console.log(index);
          let indice = this.borrar.indexOf(id);
          this.borrar.splice(indice, 1);
          console.log(this.borrar);

        }
      }

      seleccionarTodo22(){
        this.todo22 = true;
        let valores = this.docs22.length;
        for( let i = 0; i < valores; i++){
          console.log(i);
          this.borrar[i]=this.docs22[i].ID;
          console.log(this.borrar);

        }

      }
      quitarSeleccion22(){
        let valores = this.borrar.length;
        for( let i = 0; i < valores; i++){
          console.log(i);
          this.borrar.pop();
          console.log(this.borrar);
          this.todo22 = false;
        }
      }

      checkCheckBoxvalue23(event:any, index:any, id: any){
        console.log(event.target.checked)
        this.todo23 = null;
        this.valor = event.target.checked;
        if(this.valor === true){
          console.log('verdadero');
          console.log(index);
          this.borrar[index]=this.docs23[index].ID;
          console.log(this.borrar);

        }else if(this.valor === false){
          console.log('falso');
          console.log(index);
          let indice = this.borrar.indexOf(id);
          this.borrar.splice(indice, 1);
          console.log(this.borrar);

        }
      }

      seleccionarTodo23(){
        this.todo23 = true;
        let valores = this.docs23.length;
        for( let i = 0; i < valores; i++){
          console.log(i);
          this.borrar[i]=this.docs23[i].ID;
          console.log(this.borrar);

        }

      }
      quitarSeleccion23(){
        let valores = this.borrar.length;
        for( let i = 0; i < valores; i++){
          console.log(i);
          this.borrar.pop();
          console.log(this.borrar);
          this.todo23 = false;
        }
      }

      checkCheckBoxvalue24(event:any, index:any, id: any){
        console.log(event.target.checked)
        this.todo24 = null;
        this.valor = event.target.checked;
        if(this.valor === true){
          console.log('verdadero');
          console.log(index);
          this.borrar[index]=this.docs24[index].ID;
          console.log(this.borrar);

        }else if(this.valor === false){
          console.log('falso');
          console.log(index);
          let indice = this.borrar.indexOf(id);
          this.borrar.splice(indice, 1);
          console.log(this.borrar);

        }
      }

      seleccionarTodo24(){
        this.todo24 = true;
        let valores = this.docs24.length;
        for( let i = 0; i < valores; i++){
          console.log(i);
          this.borrar[i]=this.docs24[i].ID;
          console.log(this.borrar);

        }

      }
      quitarSeleccion24(){
        let valores = this.borrar.length;
        for( let i = 0; i < valores; i++){
          console.log(i);
          this.borrar.pop();
          console.log(this.borrar);
          this.todo24 = false;
        }
      }

      checkCheckBoxvalue25(event:any, index:any, id: any){
        console.log(event.target.checked)
        this.todo25 = null;
        this.valor = event.target.checked;
        if(this.valor === true){
          console.log('verdadero');
          console.log(index);
          this.borrar[index]=this.docs25[index].ID;
          console.log(this.borrar);

        }else if(this.valor === false){
          console.log('falso');
          console.log(index);
          let indice = this.borrar.indexOf(id);
          this.borrar.splice(indice, 1);
          console.log(this.borrar);

        }
      }

      seleccionarTodo25(){
        this.todo25 = true;
        let valores = this.docs25.length;
        for( let i = 0; i < valores; i++){
          console.log(i);
          this.borrar[i]=this.docs25[i].ID;
          console.log(this.borrar);

        }

      }
      quitarSeleccion25(){
        let valores = this.borrar.length;
        for( let i = 0; i < valores; i++){
          console.log(i);
          this.borrar.pop();
          console.log(this.borrar);
          this.todo25 = false;
        }
      }

      eliminarSeleccionados(){

        this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
        .then((res: any) => {

          if (res.isConfirmed) {
            for(let numero of this.borrar){
             console.log(numero);
             this.eliminarForm.ID = numero;
             this.eliminarForm.ESTATUS = 1;
             this.service.eliminarEnlace(this.eliminarForm).subscribe(
               (data: any) => {

               })
             this.alerts.alertaRealizado().then((res: any) => {
              location.reload()
             })

            }

          }

        })
      }
      eliminar(ID: any) {

        this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
          .then((res: any) => {

            if (res.isConfirmed) {
              this.eliminarForm.ID = ID;
              this.eliminarForm.ESTATUS = 1;
              this.service.eliminarEnlace(this.eliminarForm).subscribe(
                (data: any) => {


                  console.log(ID);

                })
              this.alerts.alertaRealizado().then((res: any) => {
                location.reload()
              })
            }

          })


      }

      area1() {
        localStorage.setItem('area', '1');
      }
      area2() {
        localStorage.setItem('area', '2');
      }
      area3() {
        localStorage.setItem('area', '3');
      }
      area4() {
        localStorage.setItem('area', '4');
      }
      area5() {
        localStorage.setItem('area', '5');
      }
      area6() {
        localStorage.setItem('area', '6');
      }
      area7() {
        localStorage.setItem('area', '7');
      }
      area8() {
        localStorage.setItem('area', '8');
      }
      area9() {
        localStorage.setItem('area', '9');
      }
      area10() {
        localStorage.setItem('area', '10');
      }
      area11() {
        localStorage.setItem('area', '11');
      }
      area12() {
        localStorage.setItem('area', '12');
      }
      area13() {
        localStorage.setItem('area', '13');
      }

      a82018() {
        localStorage.setItem('fecha', '2000');
        this.tri = localStorage.getItem('area');
        console.log(this.tri);
        this.fecha = localStorage.getItem('fecha');
        console.log(this.fecha);

        this.api.obtenerAreas(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), this.fecha, this.tri).subscribe(res => {
          this.docs18 = res.body
          console.log(this.docs18);

        })
      }

      a82019() {
        localStorage.setItem('fecha', '2001');
        this.tri = localStorage.getItem('area');
        console.log(this.tri);
        this.fecha = localStorage.getItem('fecha');
        console.log(this.fecha);

        this.api.obtenerAreas(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), this.fecha, this.tri).subscribe(res => {
          this.docs19 = res.body
          console.log(this.docs19);

        })
      }

      a82020() {
        localStorage.setItem('fecha', '2002');
        this.tri = localStorage.getItem('area');
        console.log(this.tri);
        this.fecha = localStorage.getItem('fecha');
        console.log(this.fecha);

        this.api.obtenerAreas(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), this.fecha, this.tri).subscribe(res => {
          this.docs20 = res.body
          console.log(this.docs20);

        })
      }

      a82021() {
        localStorage.setItem('fecha', '2003');
        this.tri = localStorage.getItem('area');
        console.log(this.tri);
        this.fecha = localStorage.getItem('fecha');
        console.log(this.fecha);

        this.api.obtenerAreas(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), this.fecha, this.tri).subscribe(res => {
          this.docs21 = res.body
          console.log(this.docs21);

        })
      }

      a82022() {
        localStorage.setItem('fecha', '2004');
        this.tri = localStorage.getItem('area');
        console.log(this.tri);
        this.fecha = localStorage.getItem('fecha');
        console.log(this.fecha);

        this.api.obtenerAreas(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), this.fecha, this.tri).subscribe(res => {
          this.docs22 = res.body
          console.log(this.docs22);

        })
      }

      a82023() {
        localStorage.setItem('fecha', '2005');
        this.tri = localStorage.getItem('area');
        console.log(this.tri);
        this.fecha = localStorage.getItem('fecha');
        console.log(this.fecha);

        this.api.obtenerAreas(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), this.fecha, this.tri).subscribe(res => {
          this.docs23 = res.body
          console.log(this.docs23);

        })
      }

      a82024() {
        localStorage.setItem('fecha', '2006');
        this.tri = localStorage.getItem('area');
        console.log(this.tri);
        this.fecha = localStorage.getItem('fecha');
        console.log(this.fecha);

        this.api.obtenerAreas(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), this.fecha, this.tri).subscribe(res => {
          this.docs24 = res.body
          console.log(this.docs24);

        })
      }

      a82025() {
        localStorage.setItem('fecha', '2006');
        this.tri = localStorage.getItem('area');
        console.log(this.tri);
        this.fecha = localStorage.getItem('fecha');
        console.log(this.fecha);

        this.api.obtenerAreas(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), this.fecha, this.tri).subscribe(res => {
          this.docs25 = res.body


        })
      }




    }
