import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Fraccion1Service } from 'src/services/Articulo8/Fraccion1.service';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-in6-f',
  templateUrl: './in6-f.component.html',
  styleUrls: ['./in6-f.component.scss']
})
export class In6FComponent implements OnInit {
  docs: any;
  docs18: any;
  docs19: any;
  docs20: any;
  docs21: any;
  docs22: any;
  docs23: any;
  docs24: any;
  docs25: any;
  docsSubsidio: any;
  boton: any;
  tri: any;
  anu: any;
  fecha: any;
  area: any;
  displayStyleEditarNombre = "none";

  borrar: string[] = [];
  valor: any;
  todo18: any;
  todo19: any;
  todo20: any;
  todo21: any;
  todo22: any;
  todo23: any;
  todo24: any;
  todo25: any;
  eliminarForm: any = {
    ESTATUS: 0,
    NOMBRE: undefined,
    ENLACE: undefined,

  };

  constructor(private service: Fraccion1Service, private general: GeneralService, private alerts: SweetAlertService) { }

  ngOnInit(): void {

    localStorage.setItem('fraccion', '6');
    localStorage.setItem('inciso', '6');

    if (localStorage.getItem('token')) {
      this.boton = 1;
    }
  }
  checkCheckBoxvalue18(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo18 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs18[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo18() {
    this.todo18 = true;
    let valores = this.docs18.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs18[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion18() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo18 = false;
    }
  }

  checkCheckBoxvalue19(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo19 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs19[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo19() {
    this.todo19 = true;
    let valores = this.docs19.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs19[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion19() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo19 = false;
    }
  }

  checkCheckBoxvalue20(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo20 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs20[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo20() {
    this.todo20 = true;
    let valores = this.docs20.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs20[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion20() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo20 = false;
    }
  }
  checkCheckBoxvalue21(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo21 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs21[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo21() {
    this.todo21 = true;
    let valores = this.docs21.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs21[i].ID;
      console.log(this.borrar);

    }

  }

  quitarSeleccion21() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo21 = false;
    }
  }
  checkCheckBoxvalue22(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo22 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs22[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo22() {
    this.todo22 = true;
    let valores = this.docs22.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs22[i].ID;
      console.log(this.borrar);

    }

  }
  quitarSeleccion22() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo22 = false;
    }
  }

  checkCheckBoxvalue23(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo23 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs23[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo23() {
    this.todo23 = true;
    let valores = this.docs23.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs23[i].ID;
      console.log(this.borrar);

    }

  }
  quitarSeleccion23() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo23 = false;
    }
  }

  checkCheckBoxvalue24(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo24 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs24[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo24() {
    this.todo24 = true;
    let valores = this.docs24.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs24[i].ID;
      console.log(this.borrar);

    }

  }
  quitarSeleccion24() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo24 = false;
    }
  }

  checkCheckBoxvalue25(event: any, index: any, id: any) {
    console.log(event.target.checked)
    this.todo25 = null;
    this.valor = event.target.checked;
    if (this.valor === true) {
      console.log('verdadero');
      console.log(index);
      this.borrar[index] = this.docs25[index].ID;
      console.log(this.borrar);

    } else if (this.valor === false) {
      console.log('falso');
      console.log(index);
      let indice = this.borrar.indexOf(id);
      this.borrar.splice(indice, 1);
      console.log(this.borrar);

    }
  }

  seleccionarTodo25() {
    this.todo25 = true;
    let valores = this.docs25.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar[i] = this.docs25[i].ID;
      console.log(this.borrar);

    }

  }
  quitarSeleccion25() {
    let valores = this.borrar.length;
    for (let i = 0; i < valores; i++) {
      console.log(i);
      this.borrar.pop();
      console.log(this.borrar);
      this.todo25 = false;
    }
  }

  eliminarSeleccionados() {

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
      .then((res: any) => {

        if (res.isConfirmed) {
          for (let numero of this.borrar) {
            console.log(numero);
            this.eliminarForm.ID = numero;
            this.eliminarForm.ESTATUS = 1;
            this.general.eliminarEnlace(this.eliminarForm).subscribe(
              (data: any) => {

              })
            this.alerts.alertaRealizado().then((res: any) => {
              location.reload()
            })

          }

        }

      })
  }
  eliminar(ID: any) {

    this.alerts.alertaConfirmacionEliminar('Eliminar documento', '¿Desea eliminar este documento?')
      .then((res: any) => {

        if (res.isConfirmed) {
          this.eliminarForm.ID = ID;
          this.eliminarForm.ESTATUS = 1;
          this.general.eliminarEnlace(this.eliminarForm).subscribe(
            (data: any) => {


              console.log(ID);

            })
          this.alerts.alertaRealizado().then((res: any) => {
            location.reload()
          })
        }

      })


  }


  ar820181() {
    localStorage.setItem('fecha', '2018');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 2005).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);

    })
  }
  ar820182() {
    localStorage.setItem('fecha', '2018');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 2006).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);

    })
  }
  ar820183() {
    localStorage.setItem('fecha', '2018');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 2007).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);

    })
  }
  ar820184() {
    localStorage.setItem('fecha', '2018');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 2008).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);

    })
  }

  ar820191() {
    localStorage.setItem('fecha', '2019');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 2005).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);

    })
  }
  ar820192() {
    localStorage.setItem('fecha', '2019');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 2006).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);

    })
  } ar820193() {
    localStorage.setItem('fecha', '2019');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 2007).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);

    })
  } ar820194() {
    localStorage.setItem('fecha', '2019');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 2008).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);

    })
  }

  ar820201() {
    localStorage.setItem('fecha', '2020');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 2005).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);

    })
  }
  ar820202() {
    localStorage.setItem('fecha', '2020');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 2006).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);

    })
  }
  ar820203() {
    localStorage.setItem('fecha', '2020');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 2007).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);

    })
  }
  ar820204() {
    localStorage.setItem('fecha', '2020');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 2008).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);

    })
  }


  ar820211() {
    localStorage.setItem('fecha', '2021');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 2005).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);

    })
  }

  ar820212() {
    localStorage.setItem('fecha', '2021');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 2006).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);

    })
  }
  ar820213() {
    localStorage.setItem('fecha', '2021');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 2007).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);

    })
  }
  ar820214() {
    localStorage.setItem('fecha', '2021');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 2008).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);

    })
  }
  ar820221() {


    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 2005).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);

    })
  }
  ar820222() {


    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 2006).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);

    })
  }
  ar820223() {


    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 2007).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);

    })
  }




  ar820224() {
    localStorage.setItem('fecha', '2022');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 2008).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);

    })
  }
  ar820225() {
    localStorage.setItem('fecha', '2022');
    this.tri = localStorage.getItem('area');
    console.log(this.tri);
    this.fecha = localStorage.getItem('fecha');
    console.log(this.fecha);

    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 2008).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);

    })
  }


  ar820231() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 2005).subscribe(res => {
      this.docs23 = res.body
      console.log(this.docs23);
    })
  }

  ar820232() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 2006).subscribe(res => {
      this.docs23 = res.body
      console.log(this.docs23);
    })
  }

  ar820233() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 2007).subscribe(res => {
      this.docs23 = res.body
      console.log(this.docs23);
    })
  }
  ar820234() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 2008).subscribe(res => {
      this.docs23 = res.body
      console.log(this.docs23);
    })
  }

  ar820241() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 2005).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs24);
    })
  }

  ar820242() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 2006).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs24);
    })
  }

  ar820243() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 2007).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs24);
    })
  }
  ar820244() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 2008).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs24);
    })
  }



  ar820251() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2025, 2005).subscribe(res => {
      this.docs25 = res.body
      console.log(this.docs25);
    })
  }

  ar820252() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2025, 2006).subscribe(res => {
      this.docs25 = res.body
      console.log(this.docs25);
    })
  }

  ar820253() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2025, 2007).subscribe(res => {
      this.docs25 = res.body
      console.log(this.docs25);
    })
  }
  ar820254() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2025, 2008).subscribe(res => {
      this.docs25 = res.body
      console.log(this.docs25);
    })
  }














  a82018() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 19).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log(this.area);
    })
  }



  a82019() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 19).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }



  a82020() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 19).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }



  a82021() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 19).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }




  a82022() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 19).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }

  a82023() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 19).subscribe(res => {
      this.docs23 = res.body

    })
  }


  a82024() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 19).subscribe(res => {
      this.docs24 = res.body

    })
  }


  a82025() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2025, 19).subscribe(res => {
      this.docs25 = res.body

    })
  }
  r82018() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 53).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log(this.area);
    })
  }

  r82019() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 53).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }
  r82020() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 53).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }
  r82021() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 53).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }
  r82022() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 53).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }
  r82023() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 53).subscribe(res => {
      this.docs23 = res.body

    })
  }

  r82024() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 53).subscribe(res => {
      this.docs24 = res.body

    })
  }
  r82025() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2025, 53).subscribe(res => {
      this.docs25 = res.body

    })
  }
  e2018() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 168).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log(this.area);
    })
  }

  e2019() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 168).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }
  e2020() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 168).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }
  e2021() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 168).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }
  e2022() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 168).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }

  e2023() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 168).subscribe(res => {
      this.docs23 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }

  e2024() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 168).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }
  e2025() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2025, 168).subscribe(res => {
      this.docs25 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }
  ce2018() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 169).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log(this.area);
    })
  }

  ce2019() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 169).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }
  ce2020() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 169).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }
  ce2021() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 169).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }
  ce2022() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 169).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }

  ce2023() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 169).subscribe(res => {
      this.docs23 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }

  ce2024() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 169).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }
  ce2025() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2025, 169).subscribe(res => {
      this.docs25 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }
  e32018() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 171).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log(this.area);
    })
  }

  e32019() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 171).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }
  e32020() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 171).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }
  e32021() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 171).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }
  e32022() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 171).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }

  e32023() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 171).subscribe(res => {
      this.docs23 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }


  e32024() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 171).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }
  e32025() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2025, 171).subscribe(res => {
      this.docs25 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }
  e82018() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 18).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log(this.area);
    })
  }

  e82019() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 18).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }
  e82020() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 18).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }
  e82021() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 18).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }
  e82022() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 18).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }

  e82023() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 18).subscribe(res => {
      this.docs23 = res.body

    })
  }

  e82024() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 18).subscribe(res => {
      this.docs24 = res.body

    })
  }

  e82025() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2025, 18).subscribe(res => {
      this.docs25 = res.body

    })
  }

  c282018() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 170).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log(this.area);
    })
  }

  c282019() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 170).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }
  c282020() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 170).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }
  c282021() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 170).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }
  c282022() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 170).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }
  c282023() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 170).subscribe(res => {
      this.docs23 = res.body
      console.log(this.docs23);
    })
  }

  c282024() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 170).subscribe(res => {
      this.docs24 = res.body
      console.log(this.docs24);
    })
  }
  c282025() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2025, 170).subscribe(res => {
      this.docs25 = res.body
      console.log(this.docs25);
    })
  }
  c82018() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 174).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log(this.area);
    })
  }

  c82019() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 174).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }
  c82020() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 174).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }
  c82021() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 174).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }
  c82022() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 174).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }
  c82023() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 174).subscribe(res => {
      this.docs23 = res.body

    })
  }

  c82024() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 174).subscribe(res => {
      this.docs24 = res.body

    })
  }
  c82025() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2025, 174).subscribe(res => {
      this.docs25 = res.body

    })
  }


  contr82018() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 51).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log(this.area);
    })
  }

  contr82019() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 51).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }
  contr82020() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 51).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }
  contr82021() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 51).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }
  contr82022() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 51).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }
  contr82023() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 51).subscribe(res => {
      this.docs23 = res.body

    })
  }
  contr82024() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 51).subscribe(res => {
      this.docs24 = res.body

    })
  }

  contr82025() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2025, 51).subscribe(res => {
      this.docs25 = res.body

    })
  }

  agua82018() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 52).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log(this.area);
    })
  }

  agua82019() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 52).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }
  agua82020() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 52).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }
  agua82021() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 52).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }
  agua82022() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 52).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }
  agua82023() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 52).subscribe(res => {
      this.docs23 = res.body

    })
  }

  agua82024() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 52).subscribe(res => {
      this.docs24 = res.body

    })
  }

  fiscal82018() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 55).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log(this.area);
    })
  }

  fiscal82019() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 55).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }
  fiscal82020() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 55).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }
  fiscal82021() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 55).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }
  fiscal82022() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 55).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }
  fiscal82023() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 55).subscribe(res => {
      this.docs23 = res.body

    })
  }

  fiscal82024() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 55).subscribe(res => {
      this.docs24 = res.body

    })
  }

  personal82018() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2018, 54).subscribe(res => {
      this.docs18 = res.body
      console.log(this.docs18);
      console.log(this.area);
    })
  }

  personal82019() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2019, 54).subscribe(res => {
      this.docs19 = res.body
      console.log(this.docs19);
      console.log(this.area);
    })
  }
  personal82020() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2020, 54).subscribe(res => {
      this.docs20 = res.body
      console.log(this.docs20);
      console.log(this.area);
    })
  }
  personal82021() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2021, 54).subscribe(res => {
      this.docs21 = res.body
      console.log(this.docs21);
      console.log(this.area);
    })
  }
  personal82022() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2022, 54).subscribe(res => {
      this.docs22 = res.body
      console.log(this.docs22);
      console.log(this.area);
    })
  }
  personal82023() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2023, 54).subscribe(res => {
      this.docs23 = res.body

    })
  }

  personal82024() {
    this.service.obtenerA82018(localStorage.getItem('fraccion'), localStorage.getItem('inciso'), 2024, 54).subscribe(res => {
      this.docs24 = res.body

    })
  }





  area1() {
    localStorage.setItem('carpeta', '2005');
    this.tri = localStorage.getItem('carpeta')
  }
  area2() {
    localStorage.setItem('carpeta', '2006');
    this.tri = localStorage.getItem('carpeta')
  }
  area3() {
    localStorage.setItem('carpeta', '2007');
    this.tri = localStorage.getItem('carpeta')
  }
  area4() {
    localStorage.setItem('carpeta', '2008');
    this.tri = localStorage.getItem('carpeta')
  }


  openModalEditarNombre() {
    this.displayStyleEditarNombre = "block";
  }

  closePopUpNombre() {
    this.displayStyleEditarNombre = "none";
  }


}
