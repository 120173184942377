<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>



  <!--Main layout-->
  <main style="margin-top: 40px;">
    <div class="container pt-4"></div>
  </main>
  <!--Main layout-->
  <main id="main">

    <section style="margin-top: 40px;">
      <app-menu-compartido></app-menu-compartido>
      <div class="section-title" data-aos="fade-up">
        <h4 style="text-align: left; margin-left: 140px;">Los servicios públicos que presta el sujeto obligado, donde se
          señale cuando menos la descripción y
          cobertura del servicio público; los recursos materiales, humanos y financieros
          asignados para la prestación del servicio público,
          y el número y tipo de beneficiarios directos e indirectos del servicio público</h4>
      </div>
    </section>

    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
          <ol>
            <li>Inicio</li>
            <li><a href="admin/articulo-8">Articulo 8</a></li>
            <li>Fracción VI</li>
            <li>Inciso B</li>
          </ol>
        </div>

      </div>
    </section><!-- End Breadcrumbs -->


    <section id="blog" class="blog">

      <div class="fondo2" data-aos="fade-up">

        <div id="scroll">
          <div class="panel-group" id="accordion" style="width: 100%;">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse2"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2018</i></button>
              </h4>
            </div>
            <div id="collapse2" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-body">
                <div class="panel-group" id="accordion1" style="margin-left:20px;">
                  <!-- ======= Inicio  areas======= -->


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018ENE()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#a1"
                          style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> ENERO</i></button>
                      </h4>
                    </div>
                    <div id="a1" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018FEB()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#FEB"
                          style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> FEBRERO</i></button>
                      </h4>
                    </div>
                    <div id="FEB" class="panel-collapse collapse in" data-bs-parent="#accordion1">
              <div class="panel-body">
                <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                  <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                  class="btn btn-outline-dark">Seleccionar Todo</button>
                  <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                  class="btn btn-outline-dark">Desahacer Selección</button>
                  <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                  class="btn btn-outline-dark">Eliminar Seleccionados</button>
                </div>
                <div class="fondo">

                  <div id="scroll">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                            documento</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let dato of docs18, let i = index">
                          <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo18">
                            <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                            <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                          </td>

                          <td>
                            <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                              class="btn btn-outline-dark">Eliminar</button>
                          </td>

                        </tr>
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
                    </div>
                  </div>




                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018MAR()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#MAR"
                          style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> MARZO</i></button>
                      </h4>
                    </div>
                    <div id="MAR" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018ABR()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#AB"
                          style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> ABRIL</i></button>
                      </h4>
                    </div>
                    <div id="AB" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018MAY()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#MAY"
                          style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> MAYO</i></button>
                      </h4>
                    </div>
                    <div id="MAY" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018JUN()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#JUN"
                          style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> JUNIO</i></button>
                      </h4>
                    </div>
                    <div id="JUN" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018JUL()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#JUL"
                          style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> JULIO</i></button>
                      </h4>
                    </div>
                    <div id="JUL" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018AGO()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#AGO"
                          style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> AGOSTO</i></button>
                      </h4>
                    </div>
                    <div id="AGO" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018SEP()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#SEP"
                          style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> SEPTIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="SEP" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018OCT()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#OCT"
                          style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> OCTUBRE</i></button>
                      </h4>
                    </div>
                    <div id="OCT" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018NOV()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#NOV"
                          style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> NOVIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="NOV" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82018DIC()" data-bs-toggle="collapse" data-bs-parent="#accordion1" href="#DIC"
                          style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> DICIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="DIC" class="panel-collapse collapse in" data-bs-parent="#accordion1">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo18()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion18()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs18, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue18($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo18">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>

                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>










                </div>
              </div>
            </div>
          </div>

          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse3"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2019</i></button>
              </h4>
            </div>
            <div id="collapse3" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-body">
                <div class="panel-group" id="accordion2" style="margin-left:20px;">
                  <!-- ======= Inicio  areas======= -->


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019ENE()" data-bs-toggle="collapse" data-bs-parent="#accordion2"
                          href="#ENE19" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> ENERO</i></button>
                      </h4>
                    </div>
                    <div id="ENE19" class="panel-collapse collapse in" data-bs-parent="#accordion2">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019FEB()" data-bs-toggle="collapse" data-bs-parent="#accordion2"
                          href="#FEB19" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> FEBRERO</i></button>
                      </h4>
                    </div>
                    <div id="FEB19" class="panel-collapse collapse in" data-bs-parent="#accordion2">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019MAR()" data-bs-toggle="collapse" data-bs-parent="#accordion2"
                          href="#MAR19" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> MARZO</i></button>
                      </h4>
                    </div>
                    <div id="MAR19" class="panel-collapse collapse in" data-bs-parent="#accordion2">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019ABR()" data-bs-toggle="collapse" data-bs-parent="#accordion2"
                          href="#AB19" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> ABRIL</i></button>
                      </h4>
                    </div>
                    <div id="AB19" class="panel-collapse collapse in" data-bs-parent="#accordion2">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019MAY()" data-bs-toggle="collapse" data-bs-parent="#accordion2"
                          href="#MAY19" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> MAYO</i></button>
                      </h4>
                    </div>
                    <div id="MAY19" class="panel-collapse collapse in" data-bs-parent="#accordion2">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019JUN()" data-bs-toggle="collapse" data-bs-parent="#accordion2"
                          href="#JUN19" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> JUNIO</i></button>
                      </h4>
                    </div>
                    <div id="JUN19" class="panel-collapse collapse in" data-bs-parent="#accordion2">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019JUL()" data-bs-toggle="collapse" data-bs-parent="#accordion2"
                          href="#JUL19" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> JULIO</i></button>
                      </h4>
                    </div>
                    <div id="JUL19" class="panel-collapse collapse in" data-bs-parent="#accordion2">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019AGO()" data-bs-toggle="collapse" data-bs-parent="#accordion2"
                          href="#AGO19" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> AGOSTO</i></button>
                      </h4>
                    </div>
                    <div id="AGO19" class="panel-collapse collapse in" data-bs-parent="#accordion2">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019SEP()" data-bs-toggle="collapse" data-bs-parent="#accordion2"
                          href="#SEP19" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> SEPTIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="SEP19" class="panel-collapse collapse in" data-bs-parent="#accordion2">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019OCT()" data-bs-toggle="collapse" data-bs-parent="#accordion2"
                          href="#OCT19" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> OCTUBRE</i></button>
                      </h4>
                    </div>
                    <div id="OCT19" class="panel-collapse collapse in" data-bs-parent="#accordion2">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019NOV()" data-bs-toggle="collapse" data-bs-parent="#accordion2"
                          href="#NOV19" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> NOVIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="NOV19" class="panel-collapse collapse in" data-bs-parent="#accordion2">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82019DIC()" data-bs-toggle="collapse" data-bs-parent="#accordion2"
                          href="#DIC19" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> DICIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="DIC19" class="panel-collapse collapse in" data-bs-parent="#accordion2">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo19()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion19()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs19, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue19($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo19">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>










                </div>
              </div>
            </div>
          </div>

          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse4"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2020</i></button>
              </h4>
            </div>
            <div id="collapse4" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-body">
                <div class="panel-group" id="accordion3" style="margin-left:20px;">
                  <!-- ======= Inicio  areas======= -->


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020ENE()" data-bs-toggle="collapse" data-bs-parent="#accordion3"
                          href="#ENE20" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> ENERO</i></button>
                      </h4>
                    </div>
                    <div id="ENE20" class="panel-collapse collapse in" data-bs-parent="#accordion3">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020FEB()" data-bs-toggle="collapse" data-bs-parent="#accordion3"
                          href="#FEB20" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> FEBRERO</i></button>
                      </h4>
                    </div>
                    <div id="FEB20" class="panel-collapse collapse in" data-bs-parent="#accordion3">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020MAR()" data-bs-toggle="collapse" data-bs-parent="#accordion3"
                          href="#MAR20" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> MARZO</i></button>
                      </h4>
                    </div>
                    <div id="MAR20" class="panel-collapse collapse in" data-bs-parent="#accordion3">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020ABR()" data-bs-toggle="collapse" data-bs-parent="#accordion3"
                          href="#AB20" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> ABRIL</i></button>
                      </h4>
                    </div>
                    <div id="AB20" class="panel-collapse collapse in" data-bs-parent="#accordion3">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020MAY()" data-bs-toggle="collapse" data-bs-parent="#accordion3"
                          href="#MAY20" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> MAYO</i></button>
                      </h4>
                    </div>
                    <div id="MAY20" class="panel-collapse collapse in" data-bs-parent="#accordion3">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020JUN()" data-bs-toggle="collapse" data-bs-parent="#accordion3"
                          href="#JUN20" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> JUNIO</i></button>
                      </h4>
                    </div>
                    <div id="JUN20" class="panel-collapse collapse in" data-bs-parent="#accordion3">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020JUL()" data-bs-toggle="collapse" data-bs-parent="#accordion3"
                          href="#JUL20" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> JULIO</i></button>
                      </h4>
                    </div>
                    <div id="JUL20" class="panel-collapse collapse in" data-bs-parent="#accordion3">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020AGO()" data-bs-toggle="collapse" data-bs-parent="#accordion3"
                          href="#AGO20" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> AGOSTO</i></button>
                      </h4>
                    </div>
                    <div id="AGO20" class="panel-collapse collapse in" data-bs-parent="#accordion3">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020SEP()" data-bs-toggle="collapse" data-bs-parent="#accordion3"
                          href="#SEP20" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> SEPTIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="SEP20" class="panel-collapse collapse in" data-bs-parent="#accordion3">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020OCT()" data-bs-toggle="collapse" data-bs-parent="#accordion3"
                          href="#OCT20" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> OCTUBRE</i></button>
                      </h4>
                    </div>
                    <div id="OCT20" class="panel-collapse collapse in" data-bs-parent="#accordion3">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020NOV()" data-bs-toggle="collapse" data-bs-parent="#accordion3"
                          href="#NOV20" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> NOVIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="NOV20" class="panel-collapse collapse in" data-bs-parent="#accordion3">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82020DIC()" data-bs-toggle="collapse" data-bs-parent="#accordion3"
                          href="#DIC20" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> DICIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="DIC20" class="panel-collapse collapse in" data-bs-parent="#accordion3">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo20()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion20()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs20, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue20($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo20">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>










                </div>
              </div>
            </div>
          </div>

          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse5"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2021</i></button>
              </h4>
            </div>
            <div id="collapse5" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-body">
                <div class="panel-group" id="accordion4" style="margin-left:20px;">
                  <!-- ======= Inicio  areas======= -->


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021ENE()" data-bs-toggle="collapse" data-bs-parent="#accordion4"
                          href="#ENE21" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> ENERO</i></button>
                      </h4>
                    </div>
                    <div id="ENE21" class="panel-collapse collapse in" data-bs-parent="#accordion4">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021FEB()" data-bs-toggle="collapse" data-bs-parent="#accordion4"
                          href="#FEB21" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> FEBRERO</i></button>
                      </h4>
                    </div>
                    <div id="FEB21" class="panel-collapse collapse in" data-bs-parent="#accordion4">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021MAR()" data-bs-toggle="collapse" data-bs-parent="#accordion4"
                          href="#MAR21" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> MARZO</i></button>
                      </h4>
                    </div>
                    <div id="MAR21" class="panel-collapse collapse in" data-bs-parent="#accordion4">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021ABR()" data-bs-toggle="collapse" data-bs-parent="#accordion4"
                          href="#AB21" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> ABRIL</i></button>
                      </h4>
                    </div>
                    <div id="AB21" class="panel-collapse collapse in" data-bs-parent="#accordion4">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021MAY()" data-bs-toggle="collapse" data-bs-parent="#accordion4"
                          href="#MAY21" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> MAYO</i></button>
                      </h4>
                    </div>
                    <div id="MAY21" class="panel-collapse collapse in" data-bs-parent="#accordion4">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021JUN()" data-bs-toggle="collapse" data-bs-parent="#accordion4"
                          href="#JUN21" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> JUNIO</i></button>
                      </h4>
                    </div>
                    <div id="JUN21" class="panel-collapse collapse in" data-bs-parent="#accordion4">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021JUL()" data-bs-toggle="collapse" data-bs-parent="#accordion4"
                          href="#JUL21" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> JULIO</i></button>
                      </h4>
                    </div>
                    <div id="JUL21" class="panel-collapse collapse in" data-bs-parent="#accordion4">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021AGO()" data-bs-toggle="collapse" data-bs-parent="#accordion4"
                          href="#AGO21" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> AGOSTO</i></button>
                      </h4>
                    </div>
                    <div id="AGO21" class="panel-collapse collapse in" data-bs-parent="#accordion4">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021SEP()" data-bs-toggle="collapse" data-bs-parent="#accordion4"
                          href="#SEP21" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> SEPTIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="SEP21" class="panel-collapse collapse in" data-bs-parent="#accordion4">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021OCT()" data-bs-toggle="collapse" data-bs-parent="#accordion4"
                          href="#OCT21" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> OCTUBRE</i></button>
                      </h4>
                    </div>
                    <div id="OCT21" class="panel-collapse collapse in" data-bs-parent="#accordion4">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021NOV()" data-bs-toggle="collapse" data-bs-parent="#accordion4"
                          href="#NOV21" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> NOVIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="NOV21" class="panel-collapse collapse in" data-bs-parent="#accordion4">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82021DIC()" data-bs-toggle="collapse" data-bs-parent="#accordion4"
                          href="#DIC21" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> DICIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="DIC21" class="panel-collapse collapse in" data-bs-parent="#accordion4">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo21()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion21()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs21, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue21($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo21">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>










                </div>
              </div>
            </div>
          </div>

          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse6"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2022</i></button>
              </h4>
            </div>
            <div id="collapse6" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-body">
                <div class="panel-group" id="accordion5" style="margin-left:20px;">
                  <!-- ======= Inicio  areas======= -->


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022ENE()" data-bs-toggle="collapse" data-bs-parent="#accordion5"
                          href="#ENE2022" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> ENERO</i></button>
                      </h4>
                    </div>
                    <div id="ENE2022" class="panel-collapse collapse in" data-bs-parent="#accordion5">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022FEB()" data-bs-toggle="collapse" data-bs-parent="#accordion5"
                          href="#FEB22" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> FEBRERO</i></button>
                      </h4>
                    </div>
                    <div id="FEB22" class="panel-collapse collapse in" data-bs-parent="#accordion5">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022MAR()" data-bs-toggle="collapse" data-bs-parent="#accordion5"
                          href="#MAR22" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> MARZO</i></button>
                      </h4>
                    </div>
                    <div id="MAR22" class="panel-collapse collapse in" data-bs-parent="#accordion5">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022ABR()" data-bs-toggle="collapse" data-bs-parent="#accordion5" href="#AB"
                          style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> ABRIL</i></button>
                      </h4>
                    </div>
                    <div id="AB" class="panel-collapse collapse in" data-bs-parent="#accordion5">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022MAY()" data-bs-toggle="collapse" data-bs-parent="#accordion5"
                          href="#MAY22" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> MAYO</i></button>
                      </h4>
                    </div>
                    <div id="MAY22" class="panel-collapse collapse in" data-bs-parent="#accordion5">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022JUN()" data-bs-toggle="collapse" data-bs-parent="#accordion5"
                          href="#JUN22" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> JUNIO</i></button>
                      </h4>
                    </div>
                    <div id="JUN22" class="panel-collapse collapse in" data-bs-parent="#accordion5">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022JUL()" data-bs-toggle="collapse" data-bs-parent="#accordion5"
                          href="#JUL22" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> JULIO</i></button>
                      </h4>
                    </div>
                    <div id="JUL22" class="panel-collapse collapse in" data-bs-parent="#accordion5">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022AGO()" data-bs-toggle="collapse" data-bs-parent="#accordion5"
                          href="#AGO22" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> AGOSTO</i></button>
                      </h4>
                    </div>
                    <div id="AGO22" class="panel-collapse collapse in" data-bs-parent="#accordion5">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022SEP()" data-bs-toggle="collapse" data-bs-parent="#accordion5"
                          href="#SEP22" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> SEPTIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="SEP22" class="panel-collapse collapse in" data-bs-parent="#accordion5">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022OCT()" data-bs-toggle="collapse" data-bs-parent="#accordion5"
                          href="#OCT22" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> OCTUBRE</i></button>
                      </h4>
                    </div>
                    <div id="OCT22" class="panel-collapse collapse in" data-bs-parent="#accordion5">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022NOV()" data-bs-toggle="collapse" data-bs-parent="#accordion5"
                          href="#NOV22" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> NOVIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="NOV22" class="panel-collapse collapse in" data-bs-parent="#accordion5">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82022DIC()" data-bs-toggle="collapse" data-bs-parent="#accordion5"
                          href="#DIC22" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> DICIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="DIC22" class="panel-collapse collapse in" data-bs-parent="#accordion5">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo22()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion22()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs22, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue22($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo22">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>










                </div>
              </div>
            </div>
          </div>

          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse7sa"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2023</i></button>
              </h4>
            </div>
            <div id="collapse7sa" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-body">
                <div class="panel-group" id="accordion57" style="margin-left:20px;">
                  <!-- ======= Inicio  areas======= -->


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023ENE()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#ENE2023" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> ENERO</i></button>
                      </h4>
                    </div>
                    <div id="ENE2023" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023FEB()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#FEB23" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> FEBRERO</i></button>
                      </h4>
                    </div>
                    <div id="FEB23" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023MAR()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#MAR23" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> MARZO</i></button>
                      </h4>
                    </div>
                    <div id="MAR23" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023ABR()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#AB23" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> ABRIL</i></button>
                      </h4>
                    </div>
                    <div id="AB23" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023MAY()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#MAY23" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> MAYO</i></button>
                      </h4>
                    </div>
                    <div id="MAY23" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023JUN()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#JUN23" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> JUNIO</i></button>
                      </h4>
                    </div>
                    <div id="JUN23" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023JUL()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#JUL23" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> JULIO</i></button>
                      </h4>
                    </div>
                    <div id="JUL23" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023AGO()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#AGO23" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> AGOSTO</i></button>
                      </h4>
                    </div>
                    <div id="AGO23" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023SEP()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#SEP23" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> SEPTIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="SEP23" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023OCT()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#OCT23" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> OCTUBRE</i></button>
                      </h4>
                    </div>
                    <div id="OCT23" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023NOV()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#NOV23" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> NOVIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="NOV23" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82023DIC()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#DIC23" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> DICIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="DIC23" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo23()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion23()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs23, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue23($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo23">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>










                </div>
              </div>
            </div>
          </div>




          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse7"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2024</i></button>
              </h4>
            </div>
            <div id="collapse7" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-body">
                <div class="panel-group" id="accordion57" style="margin-left:20px;">
                  <!-- ======= Inicio  areas======= -->


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024ENE()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#ENE2024" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> ENERO</i></button>
                      </h4>
                    </div>
                    <div id="ENE2024" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024FEB()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#FEB24" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> FEBRERO</i></button>
                      </h4>
                    </div>
                    <div id="FEB24" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024MAR()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#MAR24" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> MARZO</i></button>
                      </h4>
                    </div>
                    <div id="MAR24" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024ABR()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#AB24" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> ABRIL</i></button>
                      </h4>
                    </div>
                    <div id="AB24" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024MAY()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#MAY24" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> MAYO</i></button>
                      </h4>
                    </div>
                    <div id="MAY24" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024JUN()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#JUN24" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> JUNIO</i></button>
                      </h4>
                    </div>
                    <div id="JUN24" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024JUL()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#JUL24" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> JULIO</i></button>
                      </h4>
                    </div>
                    <div id="JUL24" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024AGO()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#AGO24" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> AGOSTO</i></button>
                      </h4>
                    </div>
                    <div id="AGO24" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024SEP()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#SEP24" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> SEPTIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="SEP24" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024OCT()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#OCT24" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> OCTUBRE</i></button>
                      </h4>
                    </div>
                    <div id="OCT24" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024NOV()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#NOV24" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> NOVIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="NOV24" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82024DIC()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#DIC24" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> DICIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="DIC24" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo24()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion24()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs24, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue24($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo24">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>










                </div>
              </div>
            </div>
          </div>


          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse725"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2025</i></button>
              </h4>
            </div>
            <div id="collapse725" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-body">
                <div class="panel-group" id="accordion57" style="margin-left:20px;">
                  <!-- ======= Inicio  areas======= -->


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82025ENE()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#ENE2025" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> ENERO</i></button>
                      </h4>
                    </div>
                    <div id="ENE2025" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo25()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion25()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs25, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue25($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo25">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82025FEB()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#FEB25" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> FEBRERO</i></button>
                      </h4>
                    </div>
                    <div id="FEB25" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo25()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion25()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs25, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue25($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo25">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>




                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82025MAR()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#MAR25" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> MARZO</i></button>
                      </h4>
                    </div>
                    <div id="MAR25" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo25()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion25()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs25, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue25($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo25">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82025ABR()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#AB25" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> ABRIL</i></button>
                      </h4>
                    </div>
                    <div id="AB25" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo25()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion25()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs25, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue25($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo25">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82025MAY()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#MAY25" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> MAYO</i></button>
                      </h4>
                    </div>
                    <div id="MAY25" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo25()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion25()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs25, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue25($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo25">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82025JUN()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#JUN25" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> JUNIO</i></button>
                      </h4>
                    </div>
                    <div id="JUN25" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo25()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion25()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs25, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue25($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo25">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82025JUL()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#JUL25" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> JULIO</i></button>
                      </h4>
                    </div>
                    <div id="JUL25" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo25()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion25()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs25, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue25($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo25">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82025AGO()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#AGO25" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> AGOSTO</i></button>
                      </h4>
                    </div>
                    <div id="AGO25" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo25()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion25()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs25, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue25($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo25">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82025SEP()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#SEP25" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> SEPTIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="SEP25" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo25()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion25()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs25, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue25($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo25">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82025OCT()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#OCT25" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> OCTUBRE</i></button>
                      </h4>
                    </div>
                    <div id="OCT25" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo25()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion25()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs25, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue25($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo25">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82025NOV()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#NOV25" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> NOVIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="NOV25" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo25()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion25()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs25, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue25($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo25">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <button (click)="a82025DIC()" data-bs-toggle="collapse" data-bs-parent="#accordion57"
                          href="#DIC25" style="background: none; border: none;">
                          <i class="bi bi-folder-fill"> DICIEMBRE</i></button>
                      </h4>
                    </div>
                    <div id="DIC25" class="panel-collapse collapse in" data-bs-parent="#accordion57">
                      <div class="panel-body">
                        <div style="margin: auto; width: 80%; height: 70px; display: flex; justify-content: right;" *ngIf="boton === 1">
                          <button style="height: 38px;" (click)="seleccionarTodo25()" type="button"
                          class="btn btn-outline-dark">Seleccionar Todo</button>
                          <button style="height: 38px;  margin-left: 10px;" (click)="quitarSeleccion25()" type="button"
                          class="btn btn-outline-dark">Desahacer Selección</button>
                          <button style="height: 38px; margin-left: 10px;"  (click)="eliminarSeleccionados()" type="button"
                          class="btn btn-outline-dark">Eliminar Seleccionados</button>
                        </div>
                        <div class="fondo">

                          <div id="scroll">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre del
                                    documento</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr *ngFor="let dato of docs25, let i = index">
                                  <td><input type="checkbox" (change)="checkCheckBoxvalue25($event, i, dato?.ID)" *ngIf="boton === 1" [checked]="todo25">
                                    <img src="assets/img/docs.png" alt="" height="30" width="30">{{dato?.NOMBRE}}
                                    <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;"> - Abrir</a>
                                  </td>
                                  <td>
                                    <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                      class="btn btn-outline-dark">Eliminar</button>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>










                </div>
              </div>
            </div>
          </div>


        </div>
















        </div>
      </div>
    </section>

  </main><!-- End #main -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
